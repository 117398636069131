/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, matchPath, useParams } from 'react-router'
import HomeIcon from '@material-ui/icons/ListAlt'
import CategoryIcon from '@material-ui/icons/Reorder'
import PeriodicIcon from '@material-ui/icons/School'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import Trackings from '../../services/trackings/trackings'
import { useStyles } from './styles'
import {  Divider, Typography, Tooltip } from '@material-ui/core'
import {  MenuItem, SelectSaraiva } from '@minha-biblioteca/saraiva-ui'
import { useMenu } from '../../contexts/MenuContext'
import services from '../../services'
import DrawerRecommendation from '../DrawerRecommendation'
import Loading from '../Loading'
import { IconFavorite } from '../Header/styles'
import { BooksContext } from '../../contexts/BooksContext'
import { LibraryContext } from '../../contexts/LibraryContext'


export default function Drawer() {
  const { category, subcategory } = useParams()
  const { isOpen, setIsOpen } = useMenu()
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [recommendation, setRecommendation] = useState([])
  const search = new URLSearchParams(location.search)
  const query = search.get('query') || ''
  const isFavoritePage = location.pathname === '/favorites';
  const { categories, fetchCategories } = useContext(BooksContext)

  const [openIndex, setOpenIndex] = useState(null);

  const handleClose = () => {
    setOpenIndex(null);
  };

  const handleOpen = (index) => {
    setOpenIndex(index);
  };

  const { libraryCode } = useContext(LibraryContext)

  const items = [
    {
      label: 'Catálogo',
      iconComponent: <HomeIcon />,
      url: '/',
      isActive: (item) =>
        location.pathname === item.url ||
        matchPath(location.pathname, { path: '/books', exact: true }),
      onClick: (item) => {
        if (item.isActive(item)) setIsOpen(false)
        history.push(item.url)
        history.go(0)
      }
    },

    {
      label: 'Favoritos',
      iconComponent:  <IconFavorite  />,
      isFavoritePage: { isFavoritePage } ,
      url: '/favorites',
      isActive: (item) => location.pathname === item.url,
      onClick: (item) => {
        { !libraryCode && history.push(item.url) }
        if (item.isActive(item)) setIsOpen(false)
      }
    },

    {
      label: 'Categorias',
      iconComponent: <CategoryIcon />,
      url: '/categories',
      isActive: (item) => matchPath(location.pathname, { path: item.url }),
      openComponent: renderCategories,
      onClick: () => setIsOpen(true)
    },
    {
      label: 'Periódicos',
      iconComponent: <PeriodicIcon />,
      url: '/periodics',
      isActive: (item) => location.pathname === item.url,
      onClick: (item) => {
        if (item.isActive(item)) setIsOpen(false)
        Trackings.trackOpenPeriodics()
        history.push(item.url)
        history.go(0)

      }
    }
  ]


  const getListBook = async (uuids) => {
    setLoading(true)
    try {
      const response = await services.Books.getListBooks(1,6,uuids)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setRecommendation(result.items)
      if (recommendation.length === 0 || recommendation === null) {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }

  const fetchSuggestions = async (content, number) => {
    setLoading(true)
    try {
      const response = await services.Suggestions.listSuggestions({
        suggestionType: content,
        suggestionsNumber: number,
      })
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()

      getListBook(result.data[0].collaborative_list)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])


  function onClickCategory(item, subitem) {
    const newSearch = new URLSearchParams()
    newSearch.set('query', query)
    if (subitem) {
      history.push({
        pathname: `/categories/${item.name}/${subitem.name.split("/").join("")}`,
        search: newSearch.toString()
      })
    } else {
      history.push({
        pathname: `/categories/${item.name}`,
        search: newSearch.toString()
      })
    }
  }

  function renderCategories(item) {
    return (
      <div className={classes.categoryRoot}>
        <Divider className={classes.divider} />
        <div className={classes.categoryContainer}>
          <Typography variant="body1" className={classes.categoryText}>
            {item.label}
          </Typography>
          <SelectSaraiva
            cascade
            label="Todas as categorias"
            value={subcategory || category}
            className={classes.categoryInput}
          >
            <div className={classes.itemsWrapper}>
              {categories.map((item) =>
                item.subcategories ? (
                  <MenuItem
                    className={classes.subCategoryItem}
                    key={item.id}
                    cascade
                    label={item.name}
                    onClick={() => onClickCategory(item)}
                  >
                    {item.subcategories.map((subitem) => (
                      <div>
                        <MenuItem
                          key={subitem.id}
                          onClick={() => onClickCategory(item, subitem)}
                        >
                          {subitem.name}
                        </MenuItem>
                      </div>
                    ))}
                  </MenuItem>
                ) : (
                  <MenuItem
                    className={classes.categoryItem}
                    key={item.id}
                    onClick={() => onClickCategory(item)}
                  >
                    {item.name}
                  </MenuItem>
                )
              )}
            </div>
          </SelectSaraiva>
        </div>
        <Divider className={classes.divider} />
      </div>
    )
  }


  return isOpen ? (
    <div className={`${classes.drawer} ${classes.drawerOpen}`}>
      {items.map((item, index) =>
        item.openComponent ? (
          React.cloneElement(item.openComponent(item), { key: index })
        ) : (
          <div
            key={index}
            className={`${classes.card} ${classes.cardOpen}`}
            onClick={() => item.onClick(item)}
            aria-hidden="true"
          >
            {React.cloneElement(item.iconComponent, {
              className: `${
                item.isActive(item) ? classes.iconActive : classes.icon
              } ${classes.iconOpen}`
            })}
            <p
              className={
                item.isActive(item)
                  ? classes.openLabelActive
                  : classes.openLabel
              }
            >
              {item.label}
            </p>
          </div>
        )
      )}
    </div>
  ) : (
    <div className={classes.drawer}>
      {items.map((item, index) => (
        libraryCode &&
        (index === 1 || index === 3) ?
        <Tooltip
          open={openIndex === index}
          onClose={handleClose}
          onOpen={() => handleOpen(index)}
          placement="right"
          title="Para ter acesso a essa função, contrate a BDS">

           <div
          key={index}
          className={classes.card}
          onClick={() => item.onClick(item)}
          aria-hidden="true"
        >


          {React.cloneElement(item.iconComponent, {
            className: item.isActive(item) ? classes.iconActive : classes.icon
          })}
          <Typography
            variant="body2"
            className={
              item.isActive(item) ? classes.labelActive : classes.label
            }
          >
            {item.label}
          </Typography>
        </div>
       </Tooltip>

        :  <div
        key={index}
        className={classes.card}
        onClick={() => item.onClick(item)}
        aria-hidden="true"
      >

        {React.cloneElement(item.iconComponent, {
          className: item.isActive(item) ? classes.iconActive : classes.icon
        })}
        <Typography
          variant="body2"
          className={
            item.isActive(item) ? classes.labelActive : classes.label
          }
        >
          {item.label}
        </Typography>

      </div>
      ))}

    </div>

  )
}