import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    marginTop: 72,
    marginBottom: 42,
    [theme.breakpoints.down(600)]: {
      marginTop: 32,
      marginBottom: 32
    },
    '& ol > li:last-child::before': {
      content: 'none'
    }
  },
  searchResultText: {
    margin: 0,
    marginBottom: 6,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down(576)]: {
      fontSize: 16,
      lineHeight: 1.5
    }
  },
  listByLabel: {
    display: 'block',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
    letterSpacing: '0.1px',
    marginTop: 16,
    marginBottom: 24
  },
  galleryBooks: {
    marginTop: 24,
    marginBottom: 48,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    '& > .MuiCard-root': {
      margin: '0 !important',
      width: 'calc(25% - 18px) !important',
      minWidth: 196,
      maxWidth: 216,
      [theme.breakpoints.down(769)]: {
        width: 'calc(33% - 16px) !important',
        minWidth: 156,
        maxWidth: 176
      },
      [theme.breakpoints.down(376)]: {
        width: 'calc(50% - 12px) !important',
        minWidth: 128,
        maxWidth: 156
      }
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    [theme.breakpoints.down(576)]: {
      margin: '0 auto',
      marginTop: 20,
      width: 268
    },
    [theme.breakpoints.down(348)]: {
      width: '94%'
    }
  }
}))

export default useStyles
