import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    top: 84,
    marginRight: 0
  },
  hide: {
    display: 'none'
  },
  drawer: {
    overflow: 'overlay',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      display: ' none'
    },
    backgroundColor: '#ffffff',
    width: 90,
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 32
  },
  drawerOpen: {
    paddingTop: 16,
    minWidth: 308
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
    width: '100%',
    maxHeight: 48,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover
    }
  },
  cardOpen: {
    minHeight: 48,
    flexDirection: 'row',
    marginBottom: 6,
    justifyContent: 'flex-start',
    padding: '0 20px'
  },
  icon: {
    margin: 0,
    marginBottom: 6,
    color: theme.palette.text.secondary,
    fontSize: 24
  },
  iconActive: {
    margin: 0,
    marginBottom: 6,
    color: theme.palette.primary.main,
    fontSize: 24
  },
  iconOpen: {
    marginRight: 30,
    marginBottom: 0
  },
  label: {
    color: theme.palette.text.secondary
  },
  labelActive: {
    color: theme.palette.primary.main
  },
  openLabel: {
    fontSize: 16,
    margin: 0,
    color: theme.palette.text.secondary,
    letterSpacing: '0.5px'
  },
  openLabelActive: {
    fontSize: 16,
    margin: 0,
    color: theme.palette.primary.main,
    letterSpacing: '0.5px'
  },
  divider: {
    width: '90%'
  },
  categoryRoot: {
    marginBottom: 6,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  categoryContainer: {
    alignSelf: 'flex-start',
    margin: '16px 0',
    padding: '0 20px',
    width: '100%'
  },
  categoryText: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 12
  },
  categoryInput: {
    width: '100%',
    display: 'flex'
  },
  categoryItem: {
    zIndex: 9998
  },
  subCategoryItem: {
    zIndex: 9999,
    '& .MuiPaper-root': {
      maxHeight: '230px !important'
    }
  },
  itemsWrapper: {
    marginLeft: 16,
    marginTop: 8,
    overflow: 'auto',
    maxHeight: 320,
    zIndex: 9999,
    '& .MuiSvgIcon-root': {
      display: 'none !important'
    }
  },
  loading: {
    marginBottom: 50
  }

}))
