import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 6,
    [theme.breakpoints.down(576)]: {
      marginBottom: 14
    }
  },

  searchResultText: {
    margin: 0,
    padding: 0,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 72,
    [theme.breakpoints.down(576)]: {
      fontSize: 16,
      lineHeight: 1.5,
      marginTop: 32
    }
  },

  viewModeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: -14,
    [theme.breakpoints.down(576)]: {
      marginBottom: -22
    }
  },

  viewModeBtnDesktop: {
    display: 'none',
    [theme.breakpoints.up(576)]: {
      display: 'flex',
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: '0.43px',
      top: -6,
      '& svg': {
        fontSize: '1.5rem !important'
      }
    }
  },

  viewModeBtnMobile: {
    display: 'none',
    [theme.breakpoints.down(576)]: {
      display: 'block',
      top: -13
    }
  },

  viewModeLabel: {
    position: 'relative',
    top: 1
  },

  menuItemIcon: {
    marginRight: 30
  },

  listByLabel: {
    display: 'block',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
    letterSpacing: '0.1px',
    marginTop: 24
  },
  recent: {
    marginTop: 72,
    marginBottom: 6
  },
  recentyOpenedDivider: {
    marginBottom: 24
  },
  loadingLastBooks: {
    height: 440
  }
}))

export default useStyles
