import { styled } from '@material-ui/core/styles'
import { MenuItem, IconButton, Drawer } from '@material-ui/core'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import MenuIcon from '@material-ui/icons/MenuRounded'
import MenuOpenIcon from '@material-ui/icons/MenuOpenRounded'

export const Menu = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center'
})

export const SandwichButton = styled(IconButton)((props) => ({
  color: props.isOpenSandwich ? '#FFFFFF' : 'black'
}))

export const DrawerMenu = styled(Drawer)((({ theme }) => ({
  width: 328,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 328,
    paddingBottom: 66,
    [theme.breakpoints.down(321)]: {
      width: 276

    }
  }
})))

export const DrawerMenuHeader = styled('div')((({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
})))

export const CloseIcon = styled(CloseSharpIcon)({
  fontSize: 24
})

export const SandwichClosedIcon = styled(MenuIcon)({
  height: 32,
  width: 32
})

export const SandwichOpenedIcon = styled(MenuOpenIcon)({
  height: 32,
  width: 32
})
