import { styled } from '@material-ui/core/styles'
import { AddCircleOutline, ExitToApp } from '@material-ui/icons'
import FavoriteIcon from '@material-ui/icons/Favorite'

export const IconFavorite = styled(FavoriteIcon)(({ isFavoritePage }) => ({
  color: isFavoritePage ? '#624C92' : 'rgba(0, 0, 0, 0.54)',
  fontSize: 24,
  marginRight: 30
}))
export const AddIcon = styled(AddCircleOutline)({
  marginRight: 24,
  marginLeft: 8
})
export const ExitIcon = styled(ExitToApp)({
  marginRight: 24,
  marginLeft: 8
})
export const Divisor = styled('div')({
  width: 32
})
