/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  buttonFilter: {
    color: '#624C92',
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '0.46px'
  },

  iconFilter: {
    marginRight: 11
  },

  selectedSaraiva: {
    marginRight: 24
  },

  selectedSaraivaWrap: {
    marginBottom: 4
  },

  menuContainer: {
    display: 'flex',
    margin: '24px 0px 24px 0px'
  },

  menuContainerWrap: {
    margin: '24px 0px 21px 24px'
  },
  removeFilter: {
    position: 'relative',
    left: '8%'
  },
}))

export default useStyles
