import React, { useContext } from 'react'
import { Tooltip, useMediaQuery } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MobileSandwichMenu } from './MobileSandwichMenu'
import { DesktopSandwichMenu } from './DesktopSandwichMenu'
import {
  IconHome, IconSchool, LoadingDiv, Paragraph
} from './styles'
import Loading from '../Loading'
import DrawerRecommendation from '../DrawerRecommendation'
import { useNavigation } from '../../hooks/useNavigation'
import { IconFavorite } from '../Header/styles'
import { LibraryContext } from '../../contexts/LibraryContext'

export const ResponsiveSandwichMenu = ({ loading, recommendation, hide = Boolean }) => {
  const location = useLocation()
  const isPeriodicsPage = location.pathname === '/periodics'
  const isMobile = useMediaQuery('(min-width: 769px)')
  const isHomePage = location.pathname === '/'
  const isFavoritePage = location.pathname === '/favorites'
  const [swithPage] = useNavigation()

  const { libraryCode } = useContext(LibraryContext)

  const drawerItens = [
    {
      nodeIcon: <IconHome isHomePage={isHomePage} />,
      label: <Paragraph color={isHomePage ? '#624C92' : 'none'}>Catálogo</Paragraph>,
      onClick: () => swithPage('/')
    },
    {
      label: loading ? (
        <LoadingDiv>
          <Loading />
        </LoadingDiv>
      ) : (
        <DrawerRecommendation recommendations={recommendation} />
      )
    },
    {
      nodeIcon: (
        <IconFavorite isFavoritePage={isFavoritePage} />
      ),
      label: (
        <Tooltip title={!libraryCode === true ? 'Para ter acesso a essa função, contrate a BDS' : ''}>
          <Paragraph color={isFavoritePage ? '#624C92' : 'none'}>
            Favoritos
          </Paragraph>
        </Tooltip>
      ),
      onClick: () => { if (!libraryCode === true) { swithPage('/favorites') } }
    },
    {
      nodeIcon: (
        <IconSchool isPeriodicsPage={isPeriodicsPage} />
      ),
      label: (
        <Paragraph color={isPeriodicsPage ? '#624C92' : 'none'}>
          Periódicos
        </Paragraph>
      ),
      onClick: () => swithPage('/periodics')
    }
  ]
  if (hide) {
    return null
  }
  return (
    <>
      {!isMobile && <MobileSandwichMenu drawerItems={drawerItens} />}
      {isMobile
       && (
       <DesktopSandwichMenu />
       )}
    </>
  )
}
export default ResponsiveSandwichMenu
ResponsiveSandwichMenu.propTypes = {
  hide: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recommendation: PropTypes.array.isRequired
}
