import { styled } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/MenuRounded'
import MenuOpenIcon from '@material-ui/icons/MenuOpenRounded'

export const Container = styled('div')({
  margin: '16px 24px 16px 5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  '& svg': {
    width: 32,
    height: 32
  }
})

export const SandwichClosedIcon = styled(MenuIcon)({
  height: 32
})

export const SandwichOpenedIcon = styled(MenuOpenIcon)({
  height: 32
})
