import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import {
  Menu,
  SandwichButton,
  DrawerMenu,
  DrawerMenuHeader,
  CloseIcon,
  SandwichClosedIcon,
  SandwichOpenedIcon
} from './styles'

export const MobileSandwichMenu = (props) => {
  const {
    isOpenSandwich, drawerItems, hide, hidden
  } = props

  const [state, setState] = useState({ openNavigateMenu: isOpenSandwich })

  const toggleDrawer = () => {
    setState((prev) => ({ ...prev, openNavigateMenu: !prev.openNavigateMenu }))
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, openNavigateMenu: isOpenSandwich }))
  }, [isOpenSandwich])

  if (hide) return null

  return (
    <Hidden {...hidden}>
      <SandwichButton edge="start" onClick={toggleDrawer} isOpenSandwich aria-label={state.openNavigateMenu ? 'Fechar Menu' : 'Abrir Menu'}>
        {state.openNavigateMenu ? <SandwichOpenedIcon /> : <SandwichClosedIcon />}
      </SandwichButton>

      <DrawerMenu
        variant="temporary"
        anchor="left"
        open={state.openNavigateMenu}
        onClose={toggleDrawer}
      >
        <DrawerMenuHeader>
          <SandwichButton
            edge="end"
            onClick={toggleDrawer}
            isOpenSandwich={isOpenSandwich}
          >
            <CloseIcon />
          </SandwichButton>
        </DrawerMenuHeader>

        <Divider />

        {drawerItems?.map((item) => (
          <Menu
            onClick={() => {
              toggleDrawer()
              item.onClick()
            }}
            key={item.label}
            dense
          >
            {item.nodeIcon}
            {item.label}
          </Menu>
        ))}
      </DrawerMenu>
    </Hidden>
  )
}

MobileSandwichMenu.propTypes = {
  isOpenSandwich: PropTypes.bool.isRequired,
  drawerItems: PropTypes.arrayOf(
    PropTypes.shape({
      nodeIcon: PropTypes.node,
      label: PropTypes.string,
      onClick: PropTypes.func
    }).isRequired
  ),
  className: PropTypes.string,
  hide: PropTypes.bool,
  hidden: PropTypes.shape({
    lgDown: PropTypes.bool,
    lgUp: PropTypes.bool,
    mdDown: PropTypes.bool,
    mdUp: PropTypes.bool,
    only: PropTypes.arrayOf(PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])),
    smDown: PropTypes.bool,
    smUp: PropTypes.bool,
    xlDown: PropTypes.bool,
    xlUp: PropTypes.bool,
    xsDown: PropTypes.bool,
    xsUp: PropTypes.bool
  })
}

MobileSandwichMenu.defaultProps = {
  drawerItems: [],
  className: '',
  hide: false,
  hidden: {
    lgDown: false,
    lgUp: false,
    mdDown: false,
    mdUp: false,
    only: null,
    smDown: false,
    smUp: false,
    xlDown: false,
    xlUp: false,
    xsDown: false,
    xsUp: false
  }
}
