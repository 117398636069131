import { styled } from '@material-ui/core/styles'
import SchoolIcon from '@material-ui/icons/School'
import HomeIcon from '@material-ui/icons/ListAlt'

export const IconSchool = styled(SchoolIcon)((props) => ({
  color: props.isPeriodicsPage ? '#624C92' : 'rgba(0, 0, 0, 0.54)',
  fontSize: 24,
  marginRight: 30
}))
export const IconHome = styled(HomeIcon)((props) => ({
  color: props.isHomePage ? '#624C92' : 'rgba(0, 0, 0, 0.54)',
  fontSize: 24,
  marginRight: 30
}))
export const Paragraph = styled('p')((props) => ({
  color: props.color
}))
export const LoadingDiv = styled('div')({
  margin: 'auto auto 50px auto'
})
