/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-indent */
import {
  EmojiObjects,
  NotificationsNone,
  Reorder,
  CardGiftcard,
  EventAvailable
} from '@material-ui/icons'
import React from 'react'
import { styled } from '@material-ui/core/styles'

import FavoriteIcon from '@material-ui/icons/Favorite'
import Event from './EventAvailable.svg'
import GiftCard from './GiftCard.svg'

export const IconFavorite = styled(FavoriteIcon)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

export const IconCardGift = styled(CardGiftcard)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

export const IconEventAvailable = styled(EventAvailable)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

const IconCategory = styled(Reorder)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

const IconNotification = styled(NotificationsNone)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

const IconLamp = styled(EmojiObjects)((props) => ({
  color: '#624C92',
  fontSize: props.size
}))

const Paragraph = styled('span')((props) => ({
  color: props.color,
  fontSize: props?.size || 14
}))

const handlerDate = (date) => {
  const today = new Date()
  const diffInTime = today.getTime() - date.getTime()
  const diffDays = diffInTime / (1000 * 3600 * 24)
  const isMonth = diffDays / 30 >= 1
  const isNotToday = diffDays / 30 > 1 / 100
  const value = isMonth ? 'months' : !isMonth && isNotToday ? 'days' : 'today'
  const months = diffDays / 30
  let diff

  switch (value) {
    case 'months':
      diff = `${Math.trunc(months)} M`
      break
    case 'days':
      if (diffDays < 1) {
        diff = 'hoje'
      } else {
        diff = `${Math.trunc(diffDays)} d`
      }
      break
    case 'today':
      diff = 'hoje'
      break
    default:
  }

  return diff
}
export const notificationItems = [
  {
    type: 'image',
    id: 9,
    onClick: () => null,
    title: 'Viva a Semana Mundial do Livro!',
    content: 'Adquira livros na Editora do Direito com 15% de desconto',
    dialog: (
      <Paragraph color="black">
        Insira o cupom{' '}
      <Paragraph color="#624C92">VALESEMANADOLIVRO15 </Paragraph>
      no site da{' '}
      <Paragraph color="#624C92"><a href="https://www.editoradodireito.com.br//">Editora do Direito </a> </Paragraph>
      <Paragraph>e ganhe 15% de desconto em todas as obras!</Paragraph>
      </Paragraph>
    ),
    time: handlerDate(new Date('04/25/2023')),
    icon: <IconEventAvailable size={55} />,
    imgSrc: GiftCard
  },
  {
    type: 'image',
    id: 8,
    onClick: () => null,
    title: 'Acervo atualizado: confira nossas novidades!',
    dialog: (
      <Paragraph color="black">
        O acervo da BDS foi atualizado! Convidamos você a navegar e conferir as novas
         obras disponiveis.
        Esperamos que encontre diversos conteúdos de seu interesse!
      </Paragraph>
    ),
    time: handlerDate(new Date('03/06/2023')),
    icon: <IconEventAvailable size={55} />,
    imgSrc: Event
  },
  {
    type: 'new',
    id: 5,
    onClick: () => null,
    title: 'Novo Recurso | Manuais',
    content: 'Acesse tutoriais de uso e acessibilidade',
    dialog: (
      <Paragraph color="black">
        Para conhecer e extrair todo o potencial da sua Biblioteca Digital
        Saraiva, agora você pode acessar os{' '}
        <Paragraph color="#624C92">tutoriais</Paragraph> de uso e
        acessibilidade. Para isso, clique em "Manual de uso" ou "Manual de
        acessibilidade"{' '}
        <Paragraph color="#624C92">localizada no rodapé da página.</Paragraph>
      </Paragraph>
    ),
    time: handlerDate(new Date('08/17/2022')),
    icon: <IconLamp size={55} />
  },
  {
    type: 'new',
    id: 4,
    onClick: () => null,
    title: 'Nova funcionalidade | Categorias',
    content: 'Encontre facilmente livros através de categorias',
    dialog: (
      <Paragraph color="black">
        Para facilitar sua{' '}
        <Paragraph color="#624C92">busca por conteúdos na BDS</Paragraph> ,
        agora você pode acessar os livros por{' '}
        <Paragraph color="#624C92">categorias.</Paragraph> Para isso, clique em
        "Categorias” e selecione alguma das opções de busca.
      </Paragraph>
    ),
    time: handlerDate(new Date('06/21/2022')),
    icon: <IconCategory size={55} />
  },
  {
    type: 'new',
    id: 3,
    onClick: () => null,
    title: 'Nova funcionalidade | Favoritos',
    content: 'Salve seus livros favoritos!',
    dialog: (
      <Paragraph color="black">
        Com o objetivo de auxiliar a{' '}
        <Paragraph color="#624C92">organização dos seus estudos, </Paragraph>
        agora a BDS possui uma área específica para você{' '}
        <Paragraph color="#624C92">
          agrupar os seus livros favoritos.{' '}
        </Paragraph>
        <Paragraph>Basta clicar no ícone </Paragraph>
        <IconFavorite size={20} />
        <Paragraph>
          {' '}
          presente no card da obra e automaticamente ela será adicionada na
          página "Favoritos".
        </Paragraph>
      </Paragraph>
    ),
    time: handlerDate(new Date('06/14/2022')),
    icon: <IconFavorite size={55} />
  },
  {
    type: 'new',
    id: 2,
    onClick: () => null,
    title: 'Nova funcionalidade | Sugestões de leitura',
    content: 'Veja recomendações de livros feitas para você!',
    dialog: (
      <Paragraph color="black">
        Com o objetivo de auxiliar no seu{' '}
        <Paragraph color="#624C92">processo de escolhas de livros, </Paragraph>
        agora a BDS possui um sistema de{' '}
        <Paragraph color="#624C92">recomendações de livros. </Paragraph>
        Na sua estante, basta clicar em "Sugestões" e você encontrará sugestões
        de obras com base no seu uso.
      </Paragraph>
    ),
    time: handlerDate(new Date('06/14/2022')),
    icon: <IconLamp size={55} />
  },
  {
    type: 'new',
    id: 1,
    onClick: () => null,
    title: 'Nova funcionalidade | Notificações',
    content: 'Fique por dentro de todas as novidades através das notificações',
    dialog: (
      <Paragraph color="black">
        Com o objetivo de
        <Paragraph color="#624C92">
          {' '}
          te deixar por dentro das nossas novidades,{' '}
        </Paragraph>
        agora a BDS possui uma ferramenta de notificação. Basta clicar no botão
        de notificação no menu superior ao lado direito e ver todas as nossas
        funcionalidades novas!
      </Paragraph>
    ),
    time: handlerDate(new Date('06/14/2022')),
    icon: <IconNotification size={52} />
  }
]
