/*eslint-disable*/
import React, { useContext, useState } from 'react'
import { Divider, MenuItem, Typography, useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useStyles } from './styles'
import { bookUrl, getAuthors, toCamelCase } from '../../utils'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import services from '../../services'
import { ORIGIN as BOOK_ORIGIN } from '../../constants/Book'
import { LibraryContext } from '../../contexts/LibraryContext'

export default function DrawerRecommendation({ recommendations }) {
  const history = useHistory()
  const classes = useStyles()
  const [showAll, setShowAll] = useState(false)
  const matches = useMediaQuery('(min-width:769px)')
  const visibleOptions = showAll ? recommendations[0].length : 3
  const toggleShowAll = () => {
    setShowAll(!showAll)
  }
  const recommendation = recommendations[0]
  
  const { libraryCode } = useContext(LibraryContext)
  
  const refreshPage = () => {
    setTimeout(() => {
      history.go(0)
      }, 400);
  }

  const renderSuggestions = () => {
    return (
      <div className={classes.container}>
        {recommendation?.slice(0, visibleOptions).map((book) => (
        <MenuItem 
          className={classes.sugestionsContent}
          onClick={() => {
            services.Trackings.trackRecommendations(book, {
            origin: 'Drawer',
            format: book.format
          })
          history.push({ pathname: bookUrl(book), state: {type: BOOK_ORIGIN.RECOMMENDED} })
          !matches && refreshPage()
        }}
        >
          <img src={book.imageUrl} className={classes.sugestionsImg} />

          <div className={classes.titleContainer}>
            <span className={classes.bookTitle}> {toCamelCase(book.titleCover)} </span>
          </div>

          <div className={classes.subtitleContainer}>
            <span className={classes.subtitle}>{getAuthors(book)}</span>
          </div>

        </MenuItem>
      ))}

      {recommendation?.length > 3 && (
        <div className={classes.container}>
          <MenuItem onClick={toggleShowAll} className={classes.showButton}>
            {!showAll ?
              <>
                <span className={classes.textBody2}>Mostrar todos</span> <ExpandMore className={classes.expandIcon} />
              </>
              :
              <>
                <span className={classes.textBody2}> Mostrar menos </span> <ExpandLess className={classes.expandIcon} />
              </>}
          </MenuItem>
        </div>
      )}
      </div>
    )
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.sugestionsTitle}>Sugestões de leitura</div>
     
      <div className={classes.container}>
        {recommendation?.length === 0 ?
        
        <div className={classes.noSuggestions}> Sugestões de leitura serão disponibilizadas após acessar um ou mais livros. </div>
        :
        libraryCode === true
          ? <Typography  className={classes.caption} variant='caption' >Para ter acesso a essa função, contrate a BDS</Typography>
          : renderSuggestions()
      
    
        }
        <Divider className={classes.divider} />
      </div>
    </div>
  )
}
