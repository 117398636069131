/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { ModularHeader } from '@minha-biblioteca/saraiva-ui'
import { LogoWidget } from '@minha-biblioteca/saraiva-ui/core/ModularHeader/widgets/LogoWidget'
import { NotificationWidget } from '@minha-biblioteca/saraiva-ui/core/ModularHeader/widgets/NotificationWidget'
import { SearchBarWidget } from '@minha-biblioteca/saraiva-ui/core/ModularHeader/widgets'
import { BottomBarWidget } from '@minha-biblioteca/saraiva-ui/core/ModularHeader/widgets/BottomBarWidget'
import { UserMenuWidget } from '@minha-biblioteca/saraiva-ui/core/ModularHeader/widgets/UserMenuWidget'
import services from '../../services'
import { useMediaQuery } from '@material-ui/core'
import logoBds from '../../assets/logo/logoBDS.png'
import SearchIcon from '@material-ui/icons/Search'
import HomeIcon from '@material-ui/icons/ListAlt'
import ResponsiveSandwichMenu from '../ResponsiveSandwichMenu'
import { useNotification } from '../../hooks/useNotification'
import { AddIcon, ExitIcon, Divisor } from './styles'
import { useNavigation } from '../../hooks/useNavigation'
import { Dialog } from '@minha-biblioteca/saraiva-ui'
import { useAuthentication } from '../../hooks/useAuthentication'
import { Reorder } from '@material-ui/icons'
import  SupportButtonForm  from '../SupportButtonForm'
import {LibraryContext } from '../../contexts/LibraryContext'


function Header({ onSearch, searchPlaceHolder }) {
  const location = useLocation()
  const [swithPage] = useNavigation()
  const [logout, activateCoupom] = useAuthentication()
  const matches = useMediaQuery('(min-width:769px)')
  const serachBarMargin = useMediaQuery('(min-width: 600px)')
  const [recommendation, setRecommendation] = useState([])
  const [loading, setLoading] = useState(true)
  const [dialogTitle, setDialogTitle] = useState('title')
  const [dialogIcon, setDialogIcon] = useState('icon')
  const [dialogText, setDialogText] = useState('text')
  const [
    getNotifications,
    setNotifications,
    clearStorage,
    getNotificationParams
  ] = useNotification()
  

  const [isOpen, setIsOpen] = useState(false)
  const [userNotifications, setUserNotifications] = useState([])
  const [st, setSt] = useState({
    showSearch: false,
    hideBottomBar: false
  })

  const useMiddleWidgetStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      justifyContent: 'center',
      maxWidth: 931,
      marginLeft: serachBarMargin ? 23 : 0,
      padding:!matches? 0 : '2px'
    }
  }))

  const middleWidgetClass = useMiddleWidgetStyles()


  const useMobileSearchBarStyles = makeStyles(() => ({
    root: {
      width: '100%'
    }
  }))

  const mobileSearchBarClass = useMobileSearchBarStyles()

  const useDesktopSearchBarStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    }
  }))
 
  const desktopSearchBarClass = useDesktopSearchBarStyles()

  const useStyles = makeStyles(() => ({
    notification: {
      paddingLeft: 4
    }
  }))

  const classes = useStyles()

  const logoutUser = async () => {
    return logout()
  }

  const menuItems = [
    {
      icon: () => <AddIcon />,
      label: 'Ativar Cupom',
      onClick: () => activateCoupom()
    },
    {
      icon: () => <ExitIcon />,
      label: 'Sair',
      onClick: () => logoutUser()
    }
  ]

  const showSearch = () => {
    setSt((prev) => ({ ...prev, hideBottomBar: true, showSearch: true }))
  }

  const hideSearch = () => {
    setSt((prev) => ({ ...prev, hideBottomBar: false, showSearch: false }))
  }

  const items = [
    {
      label: 'Catálogo',
      onClick: () => swithPage('/'),
      icon: HomeIcon,
      isActive: true
    },
    {
      label: 'Buscar',
      onClick: showSearch,
      icon: SearchIcon,
      isActive: false
    },
    {
      label: 'Categorias',
      onClick: () => swithPage('/categories'),
      icon: Reorder,
      isActive: false
    }
  ]

  const handleSearch = (searchTerm) => {
    const term = searchTerm.trim()
    if (onSearch) {
      onSearch(term)
    } else if (term !== '') {
      window.location.assign(`/books?query=${term}`)
    }
  }

  const getListBook = async (uuids) => {
    setLoading(true)
    try {
      const response = await services.Books.getListBooks(1, 6, uuids)
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      setRecommendation(result.items)
      if (recommendation.length === 0 || recommendation === null) {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  } 
  const fetchSuggestions = async (content, number) => {
    setLoading(true)
    try {
      const response = await services.Suggestions.listSuggestions({
        suggestionType: content,
        suggestionsNumber: number,
      })
      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
      const result = await response.json()
      
      getListBook(result.data[0].collaborative_list)
    } catch (err) {
      console.log(err)
    }
  }
  
  useEffect(() => {
    fetchSuggestions('collaborative', 6)
  }, [])
  useEffect(() => {
    if(recommendation.length > 6) {
      setRecommendation([])
    }
  }, [])

  useEffect(() => {
    setUserNotifications(getNotifications())
  })
  const handlerNotification = (id) => {
    const parameters = getNotificationParams(id)
    const { title, icon, text } = parameters
    setDialogTitle(title)
    setDialogIcon(icon)
    setDialogText(text)
    setIsOpen(!isOpen)
  }
  const { libraryCode } = useContext( LibraryContext ) 
  
  return (
    <>  
    <ModularHeader
      hiddenMenu={services.Users.getIntegrationType() !== 'email_password'}
      navigateMenu={location.pathname === '/tickets/activate' ? false : true}
      isFullHeight={true}
      showBackDrop={st.showSearch}
      onBackDropClick={() => hideSearch()}
      middleWidgetClass={middleWidgetClass.root}
      leftWidgets={[
        <ResponsiveSandwichMenu loading={loading} recommendation={recommendation} hide={st.hideBottomBar} />,
        <LogoWidget logo={logoBds} onClick={() => swithPage('/')}  hide={st.hideBottomBar} />
      ]}
      middleWidgets={[
        <SearchBarWidget
          hide={!st.showSearch}
          searchCallback={handleSearch}
          searchPlaceHolder={searchPlaceHolder || 'Buscar por título, autor, ISBN e assunto no catálogo'}
          onClickClose={() => hideSearch()}
          inputWrapperClasses={mobileSearchBarClass.root}
          closeBottomNavigation={() => hideSearch()}
        />,
        <SearchBarWidget
          hide={!matches}
          searchCallback={handleSearch}
          searchPlaceHolder={searchPlaceHolder || 'Buscar por título, autor, ISBN e assunto no catálogo'}
          onClickClose={() => hideSearch()}
          inputWrapperClasses={desktopSearchBarClass.root}
        />
      ]}
      rightWidgets={[
        <NotificationWidget
          items={userNotifications}
          onWidgetClick={(e) => setNotifications(e) }
          showUnreadCount={true}
          onClickItem={(id) => handlerNotification(id)}
          className={classes.notification}
          hide={st.hideBottomBar}
        />,
        <UserMenuWidget
          email={services.Users.getEmail()}
          username={services.Users.getFullName()}
          onLogin={() => null}
          isUserLogged={true}
          items={menuItems}
          hide={st.hideBottomBar || services.Users.getIntegrationType() !== 'email_password'}
        />
      ]}
      bottomWidgets={[<BottomBarWidget items={items} hide={matches} />]}
    />
    <Dialog open={isOpen} handleClose={() => setIsOpen(!isOpen)} handleConfirm={() => setIsOpen(!isOpen)} primaryButton="Fechar" title={dialogTitle}>{dialogIcon}<Divisor/>{dialogText}</Dialog>
    {libraryCode && <SupportButtonForm /> } 
    </>
    
  )
}
export default Header
