import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1300,
    marginRight: 48,
    marginBottom: 48,

    [theme.breakpoints.down('sm')]: {
      marginRight: 16,
      marginBottom: 24,
      top: ' 86vh !important'
    },
    '& .MuiCard-root': {
      marginRight: 48,

      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    '& .MuiCardContent-root': {
      paddingRight: 17,
      paddingLeft: 17,

      top: '-10px',
      left: 17
    },
    '& .MuiTypography-subtitle1': {
      lineHeight: 1,
      textAlign: 'left',
      paddingLeft: 10
    },
    '& .MuiTypography-root': {
      width: 292
    },
    '& .MuiSnackbar-root': { top: 84, marginRight: 0 }

  },
  cardTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700
  },
  card: {
    fontFamily: 'Roboto',
    fontSize: 14
  },
  footer: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: 14
  },
  icon: {
    paddingRight: 8
  }

}
))

export default useStyles
