import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useMenu } from '../../../contexts/MenuContext'
import { Container, SandwichClosedIcon, SandwichOpenedIcon } from './styles'

export const DesktopSandwichMenu = () => {
  const [isOpenSandwich, setIsOpenSandwich] = useState(false)
  const { isOpen, setIsOpen } = useMenu()

  const handlerClick = () => {
    setIsOpen(!isOpen)
    setIsOpenSandwich(!isOpenSandwich)
  }

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('/categories')) {
      setIsOpen(true)
      setIsOpenSandwich(true)
    } else {
      setIsOpen(false)
      setIsOpenSandwich(false)
    }
  }, [pathname, setIsOpen])

  return (
    <Container
      onClick={() => handlerClick()}
      onKeyDown={() => handlerClick()}
      role="button"
      tabIndex={0}
      aria-label={isOpenSandwich ? 'Fechar Menu' : 'Abrir Menu'}
    >
      {isOpenSandwich ? <SandwichOpenedIcon /> : <SandwichClosedIcon /> }
    </Container>
  )
}
