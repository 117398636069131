import React, { useState } from 'react'

import { SupportButton, Snackbar } from '@minha-biblioteca/saraiva-ui/'
import { Typography } from '@material-ui/core'
import { MailOutlineOutlined, Phone, QuestionAnswer } from '@material-ui/icons'

import useStyles from './styles'

export default function SupportButtonForm() {
  const classes = useStyles()

  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false,
    timer: 0
  })

  const handleCloseSnackBar = () => {
    setSnackbar(() => ({
      show: false
    }))
  }

  return (

    <SupportButton
      color="primary"
      classCustom={classes.root}
      textButton="Possui interesse em saber mais sobre a BDS? Fale conosco!"
      title="Contato Saraiva Educação"
      supportIcon={<QuestionAnswer />}

    >

      <Typography color="primary" className={classes.cardTitle}>
        Olá! Bem-vindo à degustação da BDS.
      </Typography>

      <br />

      <Typography className={classes.card}>
        Aqui você pode explorar amostras de alguns dos livros disponíveis em nosso acervo.
        Se desejar ter acesso à versão completa da Biblioteca Digital Saraiva,
        entre em contato conosco para mais informações.
      </Typography>

      <br />

      <Typography
        color="primary" className={classes.footer} onClick={() => {
          navigator.clipboard.writeText('falecom@saraivaeducacao.com.br')
          setSnackbar(() => ({
            show: true,
            children: 'E-mail copiado para área de transferência!'
          }))
        }}
      >
        <MailOutlineOutlined
          className={classes.icon}
        />
        falecom@saraivaeducacao.com.br
      </Typography>

      <br />

      <Typography
        color="primary" className={classes.footer} onClick={() => {
          navigator.clipboard.writeText('11) 95317-4909')
          setSnackbar(() => ({
            show: true,
            children: 'Telefone copiado para área de transferência!'
          }))
        }}
      >
        <Phone
          className={classes.icon}
        />
        (11) 95317-4909
      </Typography>

      <Snackbar
        show={snackbar.show}
        severity={snackbar.severity}
        autoHideDuration={1050}
        handleClose={handleCloseSnackBar}
        classes={useStyles}
      >
        {snackbar.children}
      </Snackbar>

    </SupportButton>

  )
}
