/* eslint-disable react/forbid-prop-types, react-hooks/exhaustive-deps */
/* eslint-disable */
import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { Grid, Divider } from '@material-ui/core'

import SnackBar from '../../components/SnackBar'

import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

import BookCollectionMode from './BookCollectionMode/index.js'
import BookGalleryMode from './BookGalleryMode/index.js'
import NoResults from './NoResults/index.js'
import Header from './Header/index.js'
import Pagination from '../../components/Pagination/index.js'
import services from '../../services'
import Loading from '../../components/Loading'
import { BooksContext } from '../../contexts/BooksContext'
import {
  Snackbar as MUISnackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  books: {
    marginTop: 21,
    marginBottom: 48,
    [theme.breakpoints.down(576)]: {
      marginTop: 24,
      marginBottom: 30
    },
    '& > .MuiPaper-root': {
      width: '100% !important',
      maxWidth: '216px'
    }
  },
  colorText: {
    color: theme.palette.text.primary,
    fontSize: 14
  },
  root: {
    marginTop: 24,
    marginRight: 24
  },
  galleryBooks: {
    marginTop: 24,
    marginBottom: 48,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    '& > .MuiCard-root': {
      margin: '0 !important',
      width: 'calc(25% - 18px) !important',
      minWidth: 196,
      maxWidth: 216,
      [theme.breakpoints.down(769)]: {
        width: 'calc(33% - 16px) !important',
        minWidth: 156,
        maxWidth: 176
      },
      [theme.breakpoints.down(376)]: {
        width: 'calc(50% - 12px) !important',
        minWidth: 128,
        maxWidth: 156
      }
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    [theme.breakpoints.down(576)]: {
      margin: '0 auto',
      marginTop: 20,
      width: 268
    },
    [theme.breakpoints.down(348)]: {
      width: '94%'
    }
  },
  root: {
    marginTop: 25
  }
})


const notificationStyle = makeStyles(() => ({
  root: {
    marginTop: 88,
    marginRight: 8,
  }
}))

function Books({ classes }) {
  const {
    data,
    viewMode,
    loading,
    fetchBooks,
    favoriteBook,
    unfavoriteBook,
    getFavoriteBooks,
    isSearch
  } = useContext(BooksContext)
  const anchorRef = useRef()

  const [alertMessage, setAlertMessage] = useState('Livro adicionado aos favoritos')
  const [severity, setSeverity] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [favorites, setFavorites] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onFavoriteClick = async (book, event, tracking) => {
    switch (event) {
      case "favorite":
          const favorite = await favoriteBook(book.uuid)
          if (favorite) {
            setAlertMessage('Livro adicionado aos favoritos')
            await services.Trackings.favoritesTracking(book, {event: tracking})
          }
      break;
      case "unfavorite":
        const data = await getFavoriteBooks(2)
        const id = data.find((item) => {
          return item.favoriteId === book.uuid
        })

        const unfavorite = await unfavoriteBook(id.id)
        if (unfavorite) {
          setAlertMessage('Livro removido dos favoritos')
        }
      break;
      default:
    }
    setShowAlert(true)
    setTimeout(async() => {
      setShowAlert(false)
    }, 800)
  }

  async function handleFavorites () {
    setIsLoading(true)
    const data = await getFavoriteBooks(1)
    setFavorites(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchBooks()
    handleFavorites()
  }, [])


  const renderBooks = () => {
    if (viewMode.name === 'Galeria') {
      return (
        <div
          className={`${classes.books} ${classes.galleryBooks}`}
        >
          {data.books.map((book, index) => (
            <BookGalleryMode book={book} key={book.uuid || index} hasFavorites={true} favorites={favorites} fluxo="Estante" favoriteClicked={(book, event, tracking) => onFavoriteClick(book, event, tracking)}/>
          ))}
        </div>
      )
    }

    return (
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="center"
        className={classes.books}
      >
        {data.books.map((book, index) => (
          <BookCollectionMode book={book} uuid={book.uuid} key={book.uuid || index } hasFavorites={true} favorites={favorites} fluxo="Estante" favoriteClicked={(book, event, tracking) => onFavoriteClick(book, event, tracking)}/>
        ))}
      </Grid>
    )
  }

  const renderPagination = () => {
    if (data.page_count === 1) {
      return null
    }

    return (
      <Pagination
        pageCount={data.page_count}
        page={Number(data.page)}
        fetchContents={fetchBooks}
      />
    )
  }

  if (loading || isLoading) return <Loading />
  if (!data.books) return null
  if (data.books.length === 0) return <NoResults />

  return (
    <>
      {showAlert && <SnackBar show={false} severity={severity} variant="standart" noClose>{alertMessage}</SnackBar>}
      <Header anchorRef={anchorRef} refresh={() => handleFavorites()}/>

      {renderBooks()}

      <Divider />

      <div className={classes.pagination}>{renderPagination()}</div>
    </>
  )
}

Books.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Books)
