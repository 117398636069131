import React from 'react'
import ReactDOM from 'react-dom'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

import Routes from './routes'
import 'sanitize.css'
import MenuProvider from './contexts/MenuContext'
import { LibraryContextProvider } from './contexts/LibraryContext'
import packageInfo from '../package.json'

const { REACT_APP_BUGSNAG_API_KEY } = process.env

Bugsnag.start({
  apiKey: REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: packageInfo.version
})
BugsnagPerformance.start({ apiKey: REACT_APP_BUGSNAG_API_KEY })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <LibraryContextProvider>
        <MenuProvider>
          <Routes />
        </MenuProvider>
      </LibraryContextProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
)
