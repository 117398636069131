/* eslint-disable */
import { notificationItems } from './data/hooks-data'
import * as _ from 'lodash'

let userNotifications = JSON.parse(localStorage.getItem('notifications')) || []

export const useNotification = () => {
  const tempNotifications = notificationItems
  let dialogModel = {text :'', title : '', icon : null}

  function createStorage() {
    if(!_.isUndefined(userNotifications) && !_.isNull(userNotifications)) return
    const currentNotifications = []
    localStorage.setItem('notifications', JSON.stringify(currentNotifications))
  }

  function clearStorage() {
      localStorage.removeItem('notifications')
  }

  function getNotifications() {
    createStorage()
    const newNotifications = tempNotifications
    newNotifications.filter((item) => {
      if (!userNotifications.includes(item.id)) {
        item.isUnread = true
      }
    })

    return newNotifications
  }

  function setNotifications(props) {
    createStorage()
    props.map((item) => {
        if(!userNotifications.includes(item) && !_.isNull(userNotifications))
        userNotifications.push(item)
    })

    localStorage.setItem('notifications', JSON.stringify(userNotifications))
  }

  function getNotificationParams(props) {
    notificationItems.map((item) => {
      if(item.id === props){
        dialogModel.text = item.dialog
        dialogModel.title = item.title
        dialogModel.icon = item.icon
      }
    })
 
    return dialogModel


  }

  return [getNotifications, setNotifications, clearStorage, getNotificationParams]
}
