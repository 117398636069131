/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { SaraivaTheme } from '@minha-biblioteca/saraiva-ui'
import Header from '../components/Header'
import { BooksProvider } from '../contexts/BooksContext'
import Drawer from '../components/Drawer/'
import { PrivacyFooter } from '@minha-biblioteca/saraiva-ui'
import { Footer } from '@minha-biblioteca/saraiva-ui/core/v2'
import { CookieBar } from '@minha-biblioteca/saraiva-ui'
import { isIOS } from 'react-device-detect'
import services from '../services/'
import { useLocation } from 'react-router'
import Logotipo from '../assets/logo/logoBds.svg'
import { useNavigation } from '../hooks/useNavigation'
import Trackings from '../services/trackings/trackings'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: 64,
    backgroundColor: '#f5f5f5',
    height: '100vh',
    [theme.breakpoints.down(600)]: {
      paddingTop: 56
    }
  },
  layoutContainer: {
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      maxHeight: isIOS ? 'calc(100vh - 56px - 80px)' : 'calc(100vh - 56px - 56px)',
    }
  },
  contentContainer: {
    maxWidth: 931,
    width: '100%',
    margin: 'auto',
    minHeight: 'calc(100vh - 64px)',
    textAlign: 'left',
    [theme.breakpoints.down(1300)]: {
      padding: '0 20px'
    },
    [theme.breakpoints.down(600)]: {
      minHeight: isIOS ? 'calc(100vh - 56px - 80px)' : 'calc(100vh - 56px - 56px)'
    }
  },
  contentContainerNoGutters: {
    padding: 0,
  },
  footer: {
    width: '100%',
    marginTop: 106,
    zIndex: 999,
    textAlign: 'left',
    '& .MuiAppBar-root': {
      zIndex: '0 !important'
    }
  },
  cookie: {
    right: 73,
    left: 166,
    position: 'fixed',
    bottom: 32,
    zIndex: 9999,
    [theme.breakpoints.down('xs')]: {
      right: 10,
      left: 10,
      bottom: isIOS ? 82 : 62
    }
  },
  cookiebar: {
    '& button': {
      minWidth: 65
    },
    '& a': {
      color: '#245A93'
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        minWidth: 65
      }
    }
  }
}))

export default function Layout({ children, onSearch, searchPlaceHolder, noGutters, noFooter }) {
  const classes = useStyles()
  const [lgpd, setLgpd] = useState(false)
  const location = useLocation()
  const [ swithPage ] = useNavigation();

  useEffect(() => {
    setLgpd(localStorage.getItem('lgpd'))
  }, [])

  function onConfirmCookies() {
    services.Trackings.trackCookies()
    setLgpd(true)
    localStorage.setItem('lgpd', true)
  }

  const footerItens = [
    [
      {label:"Manual de uso", link:"https://drive.google.com/file/d/1Dphf0A9n9QK_iWKMHyCSz9HbL4iTyNEw/view", id: "manual-de-uso"},
      {label:"Manual de acessibilidade", link:"https://drive.google.com/file/d/1w26GFbuhA7YHikxYq6qKpqXDWocyCdvs/view?usp=sharing", id: "manual-de-acessibilidade"},
      {label:"Política de Privacidade", link:"https://minhabiblioteca.com.br/politica-de-privacidade/", id: "privacidade"}
    ]
  ]

  return (
    <SaraivaTheme>
      <BooksProvider>
        <Header onSearch={onSearch} searchPlaceHolder={searchPlaceHolder} />
        <div className={classes.container}>
          {location.pathname !== '/tickets/activate' && <Drawer />}

          <div id="scroll" className={classes.layoutContainer}>
            <div className={`${classes.contentContainer} ${noGutters ? classes.contentContainerNoGutters : undefined}`}>{children}</div>
            {!noFooter && <div className={classes.footer}><Footer itens={footerItens} logoImg={Logotipo} handleLogo={() => swithPage("/")} onClickAcessibilityManual={() => Trackings.acessibilityManualTracking()} onClickUseManual={() => Trackings.manualTracking()}/></div>}
          </div>
        </div>
        {location.pathname !== '/tickets/activate' && !lgpd && (
          <div className={classes.cookie}>
            <CookieBar
              className={classes.cookiebar}
              onClick={onConfirmCookies}
            />
          </div>
        )}
      </BooksProvider>
    </SaraivaTheme >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
